<template>
    <div>
        <!-- Form Modal -->
        <b-modal
            id="modal-season-form"
            ref="myModal"
            centered
            no-close-on-backdrop
            no-close-on-esc
            @ok="submit"
        >
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5 v-if="agreement.id === null">Register Purchase Agreement</h5>
                <h5 v-if="agreement.id !== null">Edit Purchase Agreement</h5>
                
                <feather-icon
                    class="ml-4 pr-0 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="close()"
                />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button
                    size="sm"
                    variant="primary"
                    @click="ok()"
                    :disabled="saving"
                >
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button>
                <b-button
                    size="sm"
                    @click="cancel()"
                    variant="outline-secondary"
                >
                    Cancel
                </b-button>
            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{}">
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreate">
                        <b-row>
                            <b-col cols="12" md="5">
                                <b-form-group label-for="season_id" label="Season">
                                    <validation-provider #default="{ errors }" name="Season" rules="">
                                        <b-form-select
                                            id="season_id"
                                            name="season_id"
                                            size="sm"
                                            v-model="agreement.season_id"
                                            disabled
                                        >
                                            <b-form-select-option
                                                v-for="season in seasons"
                                                :key="season.id"
                                                :value="season.id"
                                            >
                                                {{ season.startYear }}/{{ season.endYear }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.season_id">{{ serverErrors.season_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row v-if="client.type !== 0">
                            <b-col cols="12">
                                <b-form-group label-for="buyer_id" label="Buyer">
                                    <validation-provider #default="{ errors }" name="Buyer" rules="">
                                        <b-form-select
                                            id="buyer_id"
                                            name="buyer_id"
                                            size="sm"
                                            v-model="agreement.buyer_id"
                                            :disabled="buyers.length === 1"
                                        >
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option
                                                v-for="buyer in buyers"
                                                :key="buyer.id"
                                                :value="buyer.id"
                                            >
                                                {{ buyer.abbreviation }}: {{ buyer.name.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.buyer_id">{{ serverErrors.buyer_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row
                            v-for="(selected, index) in formSelections"
                            :key="selected.level"
                        >
                            <b-col cols="12">
                                <b-form-group :label-for="selected.level" :label="selected.label">
                                    <validation-provider #default="{ errors }" :name="selected.label" rules="">
                                        <b-form-select
                                            :id="selected.level"
                                            :name="selected.level"
                                            size="sm"
                                            :state="errors.length > 0 ? false : null"
                                            v-model="selected.model"
                                            @change="changeFormSelection(selected.model, index)"
                                        >
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option
                                                v-for="location in selected.locations"
                                                :key="location.id"
                                                :value="location"
                                            >
                                                {{ location.name.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="5">
                                <b-form-group labe-for="code" label="Market CODE">
                                    <validation-provider #default="{ errors }" name="Market CODE" rules="">
                                        <b-form-select
                                            id="code"
                                            name="code"
                                            size="sm"
                                            v-model="agreement.market_id"
                                            @change=""
                                        >
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option
                                                v-for="market in markets"
                                                :key="market.id"
                                                :value="market.id"
                                            >
                                                {{ market.code.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.market_id">{{ serverErrors.market_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="7">
                                <b-form-group labe-for="name" label="Market Center">
                                    <validation-provider #default="{ errors }" name="Market Center" rules="">
                                        <b-form-select
                                            id="name"
                                            name="name"
                                            size="sm"
                                            v-model="agreement.market_id"
                                            @change=""
                                        >
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option
                                                v-for="market in markets"
                                                :key="market.id"
                                                :value="market.id"
                                            >
                                                {{ market.name.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.market_id">{{ serverErrors.market_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="5">
                                <b-form-group labe-for="sale_id" label="Sale #">
                                    <validation-provider #default="{ errors }" name="Sale #" rules="">
                                        <b-form-select
                                            id="sale_id"
                                            name="sale_id"
                                            size="sm"
                                            v-model="agreement.sale_id"
                                            @change=""
                                        >
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option
                                                v-for="sale in sales"
                                                :key="sale.id"
                                                :value="sale.id"
                                            >
                                                {{ sale.number }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.sale_id">{{ serverErrors.sale_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="7" v-if="client.type !== 0">
                                <b-form-group labe-for="number" label="Agreement Number">
                                    <validation-provider #default="{ errors }" name="Agreement Number" rules="">
                                        <b-form-input
                                            id="number"
                                            name="number"
                                            v-model="agreement.number"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.number">{{ serverErrors.number[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12" md="5">
                                <b-form-group labe-for="start" label="Start Ticket">
                                    <validation-provider #default="{ errors }" name="Start Ticket" rules="">
                                        <b-form-input
                                            id="start"
                                            name="start"
                                            v-model="agreement.start"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.start">{{ serverErrors.start[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="7">
                                <b-form-group labe-for="end" label="End Ticket">
                                    <validation-provider #default="{ errors }" name="End Ticket" rules="">
                                        <b-form-input
                                            id="end"
                                            name="end"
                                            v-model="agreement.end"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.end">{{ serverErrors.end[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row >
                            <b-col cols="12">
                                Tickets: 
                                <span v-if="agreement.start && agreement.end">
                                    {{ parseInt(agreement.end.trim().substring(0, agreement.end.trim().length - 1)) - parseInt(agreement.start.trim().substring(0, agreement.start.trim().length - 1)) + 1 }}
                                </span>
                            </b-col>
                        </b-row>

                        <!-- <b-card>
                            {{ agreement }}
                        </b-card> -->
                    </b-form>
                </validation-observer>
            </template>
        </b-modal>
        <!-- ./Form Modal -->

        <!-- Filters -->
        <b-card no-body>
            <b-card-body>
                <b-row>
                    <b-col cols="6" md="2">
                        <label>Season</label>
                        <b-form-select
                            size="sm"
                            v-model="agreement.season_id"
                            @change="changeSeason()"
                            disabled
                        >
                            <b-form-select-option
                                v-for="season in seasons"
                                :key="season.id"
                                :value="season.id"
                            >
                                {{ season.startYear }}/{{ season.endYear }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                    <b-col cols="6" md="3" v-if="client.type !== 0">
                        <label>Buyer</label>
                        <b-form-select
                            size="sm"
                            v-model="agreement.buyer_id"
                            @change="changeBuyer()"
                            :disabled="buyers.length === 1"
                        >
                            <b-form-select-option :value="null">Select...</b-form-select-option>
                            <b-form-select-option
                                v-for="buyer in buyers"
                                :key="buyer.id"
                                :value="buyer.id"
                            >
                                {{ buyer.abbreviation }}: {{ buyer.name.toUpperCase() }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                    <b-col
                        cols="12"
                        md="2"
                        class="mb-md-0 mb-2"
                        v-for="(toSelect, index) in selections"
                        :key="toSelect.level"
                    >
                        <label>{{ toSelect.label }}</label>
                        <b-form-select
                            size="sm"
                            v-model="toSelect.model"
                            @change="changeFilters(toSelect.model, index)"
                        >
                            <b-form-select-option :value="null">Select...</b-form-select-option>
                            <b-form-select-option
                                v-for="location in toSelect.locations"
                                :key="location.id"
                                :value="location"
                            >
                                {{ location.name.toUpperCase() }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>

                    <b-col cols="6" md="2">
                        <label>Market</label>
                        <b-form-select
                            size="sm"
                            v-model="agreement.market_id"
                            @change="changeMarket()"
                        >
                            <b-form-select-option :value="null">Select...</b-form-select-option>
                            <b-form-select-option
                                v-for="market in markets"
                                :key="market.id"
                                :value="market.id"
                            >
                                {{ market.code }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>

                    <b-col cols="6" md="2">
                        <label>Sale #</label>
                        <b-form-select
                            size="sm"
                            v-model="agreement.sale_id"
                            @change="changeSale()"
                        >
                            <b-form-select-option :value="null">Select...</b-form-select-option>
                            <b-form-select-option
                                v-for="sale in sales"
                                :key="sale.id"
                                :value="sale.id"
                            >
                                {{ sale.number }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                </b-row>
                <!-- {{ agreement }} -->
            </b-card-body>
        </b-card>
        <!-- ./Filters -->

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."
                                size="sm" />
                            <b-button
                                variant="primary"
                                size="sm"
                                @click="invokeCreateForm()"
                            >
                                <span class="text-nowrap">Register Agreement</span>
                            </b-button>
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table
                striped
                hover
                small
                ref="records"
                class="position-relative"
                :items="fetch"
                responsive
                :fields="columns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No records found"
                :sort-desc.sync="isSortDirDesc"
            >
                <template #cell(market)="data">
                    {{ data.item.sale.market.code.toUpperCase() }}
                </template>
                <template #cell(sale)="data">
                    {{ data.item.sale.number }}
                </template>
                <template #cell(ticketsCount)="data">
                    {{ data.item.capturedCount }}/{{ data.item.ticketsCount }}
                </template>
                <template #cell(created_at)="data">
                    <span class="text-nowrap">
                      {{ data.item.created_at | moment('DD/MM/YYYY') }}
                    </span>
                </template>

                <template #cell(updated_at)="data">
                    <span class="text-nowrap">
                      {{ data.item.updated_at | moment('DD/MM/YYYY') }}
                    </span>
                </template>
                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                    >

                        <template #button-content>
                        <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                        />
                        </template>

                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>
        
                        <b-dropdown-item @click="remove(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRecords"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
        BSpinner,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import { required } from '@validations'
    import store from '@/store'
    import { ref, onUnmounted, onMounted } from '@vue/composition-api'
    import agreementsStoreModule from '@/views/cromis/sales/agreement/agreementsStoreModule'
    import useAgreementsList from '@/views/cromis/sales/agreement/useAgreementsList'
    import moment from 'moment'

    export default {
        props: {},
        components: {
            BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
            BForm, BFormGroup,
            BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
            BSpinner,
            ValidationObserver, ValidationProvider,
        },
        directives: {},
        setup(props, context) {
            const dataForm = ref(null)
            const myModal = ref(null)
            const saving = ref(false)
            const serverErrors = ref(null)
            const selections = ref([])
            const formSelections = ref([])
            const levels = ref(null)
            const children = ref([])
            const seasons = ref([])
            const buyers = ref([])
            const markets = ref([])
            const sales = ref([])

            const client = ref(JSON.parse(localStorage.getItem('userData')).client)

            const agreement = ref({
                id: null,
                location_id: null,
                season_id: null,
                buyer_id: null,
                market_id: null,
                sale_id: null,
                number: null,
                start: null,
                end: false,
            })
            
            const CROMIS_STORE_MODULE_NAME = 'cromis-agreement'

            // Register module
            if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, agreementsStoreModule)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
            })

            onMounted(async () => {
                await store.dispatch('cromis-agreement/levels')
                        .then(response => {
                            levels.value = response.data.levels[0]

                            selections.value.push({
                                level: levels.value.name.toLowerCase(),
                                model: null,
                                label: levels.value.name,
                                child: levels.value.children.length > 0 ? levels.value.children[0].name.toLowerCase() : null,
                                locations: [],
                            })

                            formSelections.value.push({
                                level: levels.value.name.toLowerCase(),
                                model: null,
                                label: levels.value.name,
                                child: levels.value.children.length > 0? levels.value.children[0].name.toLowerCase() : null,
                                locations: [],
                            })

                            let list = levels.value.children

                            while(list.length > 0){
                                children.value.push(list[0])
                                if(list[0].children > 0){
                                    selections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: list[0].children[0].name.toLowerCase(), locations: [] })
                                    formSelections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: list[0].children[0].name.toLowerCase(), locations: [] })
                                }
                                else{
                                    selections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: null, locations: [] })
                                    formSelections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: null, locations: [] })
                                }

                                list = list[0].children
                            }
                        })
                        .catch(error => {
                            console.log('got error', error)
                        })

                await store.dispatch('cromis-agreement/locations')
                            .then(response => {
                                populateSelections(response.data.locations)
                            })
                            .catch(error => {
                                console.log('got error', error)
                            })

                await store.dispatch('cromis-agreement/seasons')
                            .then(response => {
                                seasons.value = response.data.seasons
                                if(seasons.value.length > 0){
                                    agreement.value.season_id = seasons.value[0].id
                                    changeSeason(agreement.value.season_id)
                                }
                            })
                            .catch(error => {
                                console.log('got error', error)
                            })

                await store.dispatch('cromis-agreement/buyers')
                            .then(response => {
                                buyers.value = response.data.buyers
                                if(buyers.value.length === 1){
                                    agreement.value.buyer_id = buyers.value[0].id
                                }
                            })
                            .catch(error => {
                                console.log('got error', error)
                            })

                await store.dispatch('cromis-agreement/markets')
                            .then(response => {
                                markets.value = response.data.markets
                            })
                            .catch(error => {
                                console.log('got error', error)
                            })

                await store.dispatch('cromis-agreement/sales', { season_id: agreement.value.season_id })
                            .then(response => {
                                sales.value = response.data.sales
                            })
                            .catch(error => {
                                console.log('got error', error)
                            })
            })

            const populateSelections = (locations) => {
                for(let i = 0; i < locations.length; i++){
                    for(let x = 0; x < selections.value.length; x++){
                        if(locations[i].level.name.toLowerCase() === selections.value[x].level){
                            selections.value[x].locations.push(locations[i])

                            if(x === 0){
                                formSelections.value[x].locations.push(locations[i])
                            }
                        }
                    }
                }
            }

            const changeFilters = async (location, index) => {
                market_id.value = null
                agreement.value.market_id = null

                sale_id.value = null
                agreement.value.sale_id = null

                markets.value.splice(0)
                sales.value.splice(0)

                if(!location){
                    for(let i = index + 1; i < selections.value.length; i++){
                        selections.value[i].locations.splice(0)
                        selections.value[i].model = null
                    }

                    if(index > 0){
                        locationFilter.value = selections.value[index - 1].model.id
                    }
                    else{
                        locationFilter.value = null
                    }
                }
                else{
                    if(selections.value[index + 1]){
                        selections.value[index + 1].locations.splice(0)
                    }

                    location.children.map((child) => {
                        selections.value[index + 1].locations.push(child)
                    })

                    if(location.children.length > 0){
                        selections.value[index + 1].model = null
                    }

                    locationFilter.value = selections.value[index].model.id
                }

                agreement.value.location_id = locationFilter.value

                await store.dispatch('cromis-agreement/markets', { location_id: locationFilter.value })
                            .then(response => {
                                markets.value = response.data.markets
                            })
                            .catch(error => {
                                console.log('got error', error)
                            })
            }

            const changeSeason = async () => {
                season_id.value = agreement.value.season_id
            }

            const changeBuyer = async () => {
                buyer_id.value = agreement.value.buyer_id
            }

            const changeMarket = async () => {
                market_id.value = agreement.value.market_id

                sale_id.value = null
                agreement.value.sale_id = null

                sales.value.splice(0)

                await store.dispatch('cromis-agreement/sales', { season_id: season_id.value, buyer_id: buyer_id.value, market_id: market_id.value })
                            .then(response => {
                                sales.value = response.data.sales
                            })
                            .catch(error => {
                                console.log('got error', error)
                            })
            }

            const changeSale = async () => {
                sale_id.value = agreement.value.sale_id
            }

            const changeFormSelection = async (location, index) => {
                market_id.value = null
                agreement.value.market_id = null

                sale_id.value = null
                agreement.value.sale_id = null

                if(!location){
                    for(let i = index + 1; i < formSelections.value.length; i++){
                        formSelections.value[i].locations.splice(0)
                        formSelections.value[i].model = null
                    }

                    agreement.value.location_id = null
                }
                else{
                    agreement.value.location_id = null

                    if(formSelections.value[index + 1]){
                        formSelections.value[index + 1].locations.splice(0)
                    }

                    location.children.map((child) => {
                        formSelections.value[index + 1].locations.push(child)
                    })

                    if(location.children.length > 0){
                        formSelections.value[index + 1].model = null
                    }

                    if(index === formSelections.value.length - 1){
                        agreement.value.location_id = location.id
                    }
                }
            }

            const {
                fetch,
                columns,
                perPage,
                currentPage,
                totalRecords,
                from,
                to,
                meta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                records,
                refetch,

                // Filers
                locationFilter,
                season_id,
                buyer_id,
                market_id,
                sale_id,
            } = useAgreementsList()

            // Form Methods
            const invokeUpdateForm = (item) => {
                console.log(item)
                serverErrors.value = null
                
                let location = item.sale.market.address.location
                let selectedLocations = []
                while(location){
                    selectedLocations.push({ level: location.level.name.toLowerCase(), id: location.id })
                    location = location.parent
                }

                for(let i = 0; i < formSelections.value.length; i++){
                    for(let n = 0; n < selectedLocations.length; n++){
                        if(formSelections.value[i].level === selectedLocations[n].level){
                            for(let x = 0; x < formSelections.value[i].locations.length; x++){
                                if(formSelections.value[i].locations[x].id === selectedLocations[n].id){
                                    formSelections.value[i].model = formSelections.value[i].locations[x]

                                    if((i + 1) < formSelections.value.length){
                                        formSelections.value[i + 1].locations = formSelections.value[i].locations[x].children
                                    }
                                }
                            }
                        }
                    }
                }

                agreement.value = {
                    id: item.id,
                    location_id: item.sale.market.address.location.id,
                    season_id: item.sale.season.id,
                    buyer_id: item.sale.buyer.id,
                    market_id: item.sale.market.id,
                    sale_id: item.sale.id,
                    number: item.number,
                    start: item.start,
                    end: item.end,
                }

                myModal.value.show()
            }

            const invokeCreateForm = () => {
                serverErrors.value = null

                formSelections.value.map((form) => {
                    form.model = null
                })

                agreement.value = {
                    id: null,
                    location_id: agreement.value.location_id,
                    season_id: agreement.value.season_id,
                    buyer_id: agreement.value.buyer_id,
                    market_id: agreement.value.market_id,
                    sale_id: agreement.value.sale_id,
                    number: null,
                    start: null,
                    end: null,
                }

                myModal.value.show()
            }

            const isFormValid = async () => {
                let isValid = false 
                await dataForm.value.validate().then(success => {
                    isValid = success
                })

                return isValid
            }

            const submit = (bvModalEvt) => {
                bvModalEvt.preventDefault()

                serverErrors.value = null
                // Handle form submit
                if (agreement.value.id === null || agreement.value.id === 0)
                    handleCreate()
                else
                    handleUpdate(agreement.value)
            }

            const handleCreate = async () => {
                saving.value = true
                const valid = await isFormValid()

                if(!valid){
                    saving.value = false
                    return
                }

                
                await store.dispatch('cromis-agreement/create', agreement.value)
                    .then(response => {
                        refetch()
                        saving.value = false

                        myModal.value.hide()

                        context.root.$swal({
                            icon: 'success',
                            text: `Purchase agreement registered successfully!`,
                            showConfirmButton: true,
                            timer: 3000,
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })
                    })
                    .catch(error => {
                        saving.value = false
                        if(error.response.status === 422){
                            serverErrors.value = error.response.data.errors
                        }
                        else{
                            context.root.$swal({
                                icon: 'error',
                                title: 'Server Error',
                                text: 'Something went wrong. See tech support',
                                showConfirmButton: true,
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                                buttonsStyling: false,
                            })
                        }
                    })
            }

            const handleUpdate = async (item) => {
                saving.value = true
                const valid = await isFormValid()

                if(!valid){
                    saving.value = false
                    return
                }

                await store.dispatch('cromis-agreement/update', { id: item.id, data: item })
                    .then(response => {
                        refetch()
                        saving.value = false

                        myModal.value.hide()

                        context.root.$swal({
                            icon: 'success',
                            text: `Changes to agreement # ${response.data.number} of ${response.data.sale.market.code} sale ${ response.data.sale.number } has been saved successfully!`,
                            showConfirmButton: true,
                            timer: 3000,
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                            buttonsStyling: false,
                        })
                    })
                    .catch(error => {
                        saving.value = false
                        if(error.response.status === 422){
                            serverErrors.value = error.response.data.errors
                        }
                        else{
                            context.root.$swal({
                                icon: 'error',
                                title: 'Server Error',
                                text: 'Something went wrong. See tech support',
                                showConfirmButton: true,
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                                buttonsStyling: false,
                            })
                        }
                    })
            }

            const remove = async (id) => {
                await store.dispatch('cromis-agreement/remove', id)
                            .then(response => {
                                refetch()
                            })
                            .catch(error => {
                                context.root.$swal({
                                    icon: 'error',
                                    title: 'Server Error',
                                    text: 'Something went wrong. See tech support',
                                    showConfirmButton: true,
                                    customClass: {
                                        confirmButton: 'btn btn-danger',
                                    },
                                    buttonsStyling: true,
                                })
                            })
            }

            return {
                // Data
                client,
                selections,
                formSelections,
                levels,
                children,
                seasons,
                buyers,
                markets,
                agreement,
                sales,

                fetch,
                columns,
                perPage,
                currentPage,
                totalRecords,
                from,
                to,
                meta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                records,
                refetch,

                // Methods
                required,
                saving,
                serverErrors,
                dataForm,
                myModal,
                changeFilters,
                changeSeason,
                changeBuyer,
                changeMarket,
                changeSale,
                changeFormSelection,
                locationFilter,
                invokeCreateForm,
                invokeUpdateForm,
                submit,
                isFormValid,
                handleCreate,
                handleUpdate,
                remove,
            }
        }
    }
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>